<template>
  <v-toolbar flat>
    <v-row>
      <v-col cols="2">
        <v-btn
          icon
          dark
          @click="closeDialog(); resetFormInput();"
        >
          <v-icon> {{ icons.mdiClose }}</v-icon>
        </v-btn>
      </v-col>
      <v-col class="d-flex align-center justify-center">
        <v-toolbar-title
          :class="'header'"
          class="text-center text-subtitle-1 font-weight-bold"
        >
          {{ title }}
        </v-toolbar-title>
      </v-col>
      <v-col cols="2">
        <!-- ini cuma agar dia ketengah -->
        <v-spacer></v-spacer>
      </v-col>
    </v-row>
  </v-toolbar>
</template>

<script>
import { mdiClose } from '@mdi/js'

export default {
  name: 'ToolbarWithXButton',
  props: {
    title: { type: String, required: true },
  },
  setup() {
    return {
      icons: {
        mdiClose,
      },
    }
  },
  methods: {
    closeDialog() {
      this.$emit('closeDialog', false)
    },
    resetFormInput() {
      //console.log('test')
      this.$emit('resetFormInput')
    },
  }
}
</script>

<style>
</style>