var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticClass:"mb-2"},[_c('v-col',{attrs:{"cols":"12","sm":"8","justify":"center","align":"center"}},[_c('div',{staticClass:"d-flex mt-6",style:(("justify-content: " + (_vm.$vuetify.breakpoint.xs ? 'center' : '')))},[_c('v-btn',{staticClass:"ma-2",attrs:{"depressed":"","x-small":""},on:{"click":function($event){return _vm.$refs.calendar.prev()}}},[_c('v-icon',{attrs:{"dense":"","color":"black"}},[_vm._v(" "+_vm._s(_vm.icons.mdiChevronLeft)+" ")])],1),(_vm.$refs.calendar)?_c('p',{staticClass:"font-weight-black black--text mt-3 text-subtitile-2"},[_vm._v(" "+_vm._s(_vm.$refs.calendar.title)+" ")]):_vm._e(),_c('v-btn',{staticClass:"ma-2",attrs:{"depressed":"","x-small":""},on:{"click":function($event){return _vm.$refs.calendar.next()}}},[_c('v-icon',{attrs:{"dense":"","color":"black"}},[_vm._v(" "+_vm._s(_vm.icons.mdiChevronRight)+" ")])],1)],1)]),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-select',{staticClass:"text-capitalize",class:_vm.$vuetify.breakpoint.xs ? '' : 'mt-6',attrs:{"items":_vm.types,"dense":"","outlined":"","hide-details":"","label":"type"},model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}})],1)],1),_c('v-sheet',{attrs:{"height":"600"}},[_c('v-calendar',{ref:"calendar",attrs:{"color":"primary","weekdays":_vm.weekday,"type":_vm.type,"events":_vm.events,"event-overlap-mode":_vm.mode,"event-overlap-threshold":30,"event-color":_vm.getEventColor},on:{"click:more":_vm.viewDay,"click:date":_vm.viewDay},scopedSlots:_vm._u([{key:"day-label",fn:function(ref){
var date = ref.date;
var day = ref.day;
var present = ref.present;
return [_c('v-btn',{staticClass:"mb-1",attrs:{"fab":"","small":"","color":_vm.hasEvent(date) ? 'info' : present ? 'primary' : '',"disabled":false},on:{"click":function($event){return _vm.viewDay({ date: date })}}},[_c('span',{domProps:{"textContent":_vm._s(day)}})])]}},{key:"day-body",fn:function(ref){
var date = ref.date;
var week = ref.week;
var event = ref.event;
return [_c('div',{staticClass:"v-current-time",class:{ first: date === week[0].date },style:({ top: _vm.nowY })},[_vm._v(" "+_vm._s(event)+" ")])]}},{key:"event",fn:function(ref){
var event = ref.event;
return [(_vm.type !== 'month')?_c('div',{staticClass:"mini-banner-post",style:(("margin-left: " + (event.marginLeft) + "px"))},[_c('span',[_vm._v(_vm._s(event.time.substr(0, 5)))]),_c('v-img',{attrs:{"lazy-src":event.banner,"height":"75","width":"75","src":event.banner}})],1):_vm._e(),(_vm.type === 'month')?_c('div',{staticStyle:{"background":"var(--v-info-base)"}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(event.time.substr(0, 5)))]),_c('span',[_vm._v(" "+_vm._s(event.name.length > 13 ? ((event.name.substr(0, 14)) + "...") : event.name))])]):_vm._e()]}}]),model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }