<template>
  <v-container>
    <v-dialog
      v-model="isDialogVisible"
      max-width="800"
      max-height="600"
    >
      <v-card>
        <ToolbarWithXButton 
          :title="titleForToolbar"
          @closeDialog="closeDialog"
          @resetFormInput="resetFormInput"
        />
        <v-divider></v-divider>
        <v-row no-gutters>
          <v-col>
            <v-text-field
              v-model="formInput.title"
              class="mx-6 mt-6"
              label="Campaign Title"
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col>
            <v-select
              v-model="formInput.approvers"
              class="mx-6 text-field-font-style"
              :items="approverSelectionItems"
              :item-text="item => item.filter ? item.role : item.text"
              label="Approval"
              outlined
              multiple
            >
            </v-select>
          </v-col>
        </v-row>
        <v-card
          flat
          p-2
          class="ma-6"
          :color="'#FAFAFA'"
        >
          <!-- End button shown on display size sm and down -->
          <v-data-table
            v-model="selected"
            class="mx-6"
            :headers="headers"
            :items="channelItems"
            :items-per-page="5"
            item-key="_id"
            multi-sort
            show-select
          >
            <template v-slot:item.action="{item}">
              <!-- ,index -->

              <!-- {{ !item.username }} -->
              <!-- {{ component_selected.some(element => element == item.index) }} -->
              <v-select
                v-if="!item.username && !item.profileKey && component_selected.some(element => element == item.index)"
                :id="item.id"
                v-model="formInput.channelType[item.index]"
                class="mt-4"
                label="Select Contact(s)"
                outlined
                multiple
                :items="customerGroupsSelectionItems"
                :item-text="item => item.textForVSelect || item.list_name"
                return-object
                chips
              >
              </v-select>
            </template>
          </v-data-table>
        </v-card>
        <v-divider></v-divider>
        <v-row class="my-4 mx-7 justify-end">
          <v-btn
            class="text-subtitle-2 text-capitalize"
            color="primary"
            :class="'text-field-font-style'"
              
            order="last"
            depressed
            @click="createCampaign"
          >
            Create
          </v-btn>
        </v-row>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
/* eslint-disable no-return-assign */
/* eslint-disable eqeqeq */
/* eslint-disable */
import { mdiPlus, mdiMagnify, mdiTrashCanOutline, mdiCheckboxBlankOutline, mdiCloseBox } from '@mdi/js'
import ToolbarWithXButton from '../toolbar/ToolbarWithXButton.vue'
const API_KEY = process.env.VUE_APP_AYRSHARE_KEY
export default {
  name: 'CreateCampaignDialog',
  components: { ToolbarWithXButton },
  setup() {
    return {
      icons: {
        mdiPlus,
        mdiMagnify,
        mdiTrashCanOutline,
        mdiCheckboxBlankOutline,
        mdiCloseBox,
      },
      headers: [
        { text: 'Name', value: 'label' },
        { text: 'Type', value: 'type' },
        { text: 'Target', value: 'action', align: 'center' },
      ],
    }
  },
  props: {
    value: { type: Boolean, required: false },
    value_isForUpdate: { type: Boolean, required: false },
    value_ID_for_editing: { type: String, required: false },
  },
  data() {
    return {
      channelItems: [],

      showSelectContactDialog: false,

      formInput: {
        title: '',
        approvers: [],
        contacts: [],
        channelType: [],
      },

      custList: [],
      allContact: [],
      filterAllContact: '',
      filter: '',
      activeUserAyrshare: false,

      selected: [],
    }
  },
  computed: {
    visual_selectAllIcon() {
      if (this.validate_allContactsOnThisChannelAreSelected) return this.icons.mdiCloseBox
      // if (this.likesSomeFruit) return 'mdi-minus-box'
      return this.icons.mdiCheckboxBlankOutline
    },
    validation_allContactsOnThisChannelAreSelected() {
      return this.selectedFruits.length === this.fruits.length
    },
    ID_for_editing: {
      get() {
        return this.value_ID_for_editing
      },
    },
    isDialogVisible: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
    channels() {
      return this.channelItems
    },
    user() {
      return this.$store.getters['auth/getUser']
    },
    selectedContacts() {
      return this.$store.state?.campaign_manager?.selectedContacts
    },

    //= =========== untuk items Approvers ============
    component_listRoles() {
      const data = this.$store.getters['global/getRoleSettings']
      data.name = data.role

      return data
    },
    component_listMembers() {
      return this.$store.getters['auth/getListUsers']
    },
    listRolesAndMembers() {
      const SELECT_BY_ROLE = { header: 'Select By Role' }
      const SELECT_BY_NAME = { header: 'Select By Name' }

      return [SELECT_BY_ROLE, ...this.component_listRoles, SELECT_BY_NAME, ...this.component_listMembers]
    },
    // ::==============================================================================:: //

    specifiedCampaignData() {
      if (!this.value_isForUpdate) {
        this.resetFormInput()

        return {}
      }

      return this.$store.getters['campaign_manager/getSpecifiedCampaignsData']
    },

    //= =========== untuk v-select ============
    //! cust list & group dari dispatch langsung & formatted
    component_customerGroups() {
      const data = this.custList.filter(x => {
        let isFiltered = false

        if (this.filter !== '') {
          for (const field in x) {
            if (x[field] && x[field].toString().toLowerCase().includes(this.filter.toLowerCase())) {
              isFiltered = true
            }
            if (field === 'last_updated' && x[field] && !isFiltered) {
              const dateString = this.$moment(x[field]).format('DD MM YYYY HH:mm:ss')
              isFiltered = dateString.toString().toLowerCase().includes(this.filter.toLowerCase())
            }
          }
        } else {
          isFiltered = true
        }

        if (isFiltered) isFiltered = x.list_id !== 'default'

        return isFiltered
      })
      this.$store.commit('global/SET_CUSTOMER_GROUPS', data)
      const mappedData = data.map(custGroup => {
        return {
          ...custGroup,
          textForVSelect: `${custGroup.list_name} (${custGroup.contacts.length})`,
        }
      })
      return mappedData
    },
    titleForToolbar() {
      if (this.value_isForUpdate) return 'Edit Campaign'
      return 'Create Campaign'
    },
    component_customerListContacts() {
      return this.allContact.filter(x => {
        let isFiltered = false

        if (this.filterAllContact !== '') {
          for (const field in x) {
            if (x[field] && x[field].toString().toLowerCase().includes(this.filterAllContact.toLowerCase())) {
              isFiltered = true
            }
          }
        } else {
          isFiltered = true
        }
        return isFiltered
      })
    },
    customerGroupsSelectionItems() {
      return [
        {
          textForVSelect: `All customers (${this.component_customerListContacts.length})`,
          contacts: this.component_customerListContacts,
        },
        ...this.component_customerGroups,
      ]
    },
    component_allContactsOfCustomerGroups() {
      const temporaryArray = []
      this.component_customerGroups?.forEach(group => temporaryArray.push(...group.contacts))
      return temporaryArray
    },
    approverSelectionItems() {
      return [
        { header: 'Select By Role' },
        ...this.component_valueTextForRoles,
        { header: 'Select By Name' },
        ...this.component_mappedValueTextForNames,
      ]
    },
    component_mappedValueTextForNames() {
      const data = this.$store.getters['auth/getListUsers']
      const mappedData = data.map(e => {
        return { value: `${e.role}-${e.email}`, text: e.email, role: e.role }
      })

      return mappedData
    },
    component_valueTextForRoles() {
      return this.$store.getters['global/getRoleSettings']
    },
    // component_targetText() {
    //   const data = this.formInput.channelType.map(channels => channels.map(channel => channel.list_name))
    //   return data
    // },
    component_selected() {
      return this.selected?.map(selectedItem => selectedItem.index)
    },
    validation_buttonDisabledIfNoContactsSelected() {
      const contactsLength = this.formInput.contacts.length
      //test
      return contactsLength
      if (!contactsLength) return false

      return true
    },
  },
  watch: {
    specifiedCampaignData: {
      handler(newCampaign, oldCampaign) {
        //console.log('newCampaign', newCampaign, typeof newCampaign)
        //console.log('oldCampaign', oldCampaign, typeof oldCampaign)
        // //console.log(newCampaign !== oldCampaign ? 'Different' : 'Same')
        if (this.$route.name === 'marketing-calendar') {
          this.formInput = this.specifiedCampaignData
        }

        if (!oldCampaign && !Object.keys(newCampaign).length) return

        // [Dio]: jika oldCampaign undefined
        if (!Object.keys(oldCampaign).length === 0 && newCampaign) {
          //console.log(`1111111111111111`)
          this.formInput = {
            title: this.specifiedCampaignData.title,
            approvers: this.specifiedCampaignData.approvers,
            channelType: this.specifiedCampaignData.channelType,
          }
        }

        if (Object.entries(newCampaign).toString() !== Object.entries(oldCampaign).toString()) {
          //console.log(`2222222222222222`)
          this.formInput = {
            title: this.specifiedCampaignData.title,
            approvers: this.specifiedCampaignData.approvers,
            channelType: this.specifiedCampaignData.channelType,
          }
        }
      },
      deep: true,
    },
    selected: {
      handler(newCampaignSelected, oldCampaignSelected) {
        //console.log('newCampaignSelected', newCampaignSelected)
        //console.log('oldCampaignSelected', oldCampaignSelected)

        //console.log(oldCampaignSelected.length)

        if (!oldCampaignSelected.length) {
          newCampaignSelected?.forEach(campaignItem => {
            //console.log(campaignItem.phone_number && !campaignItem.username)
            if (campaignItem.phone_number && !campaignItem.username) {
              this.formInput.channelType[campaignItem.index] = []
              this.formInput.channelType[campaignItem.index].push(this.customerGroupsSelectionItems[0])
            }
          })
        }
      },
      deep: true,
    },
  },
  async created() {
    //console.log('CreateCampaignDialog created lifecycle invoked')
    await this.$store
      .dispatch('auth/fetchListUser', {
        sub_id: this.user.sub_id,
      })
      .then(() => {
        this.loading = false
      })
    const {
      data: { value },
    } = await this.$store.dispatch('global/getSettingsByKey', {
      sub_id: this.user.sub_id,
      token: this.user.token,
      key: 'instances',
    })

    // untuk merefleksikannya ke FE
    const temporaryChannelArray = []
    value?.forEach((element, index) => {
      // ini untuk ditampilkan di list
      if (element.hasOwnProperty('profileKey')) {
        element?.activeSocialAccounts?.forEach(sosmed => {
          const formatedSosmed = {
            ...sosmed,
            type: sosmed.label_server,
          }
          temporaryChannelArray.push(formatedSosmed)
        })
      } else if (!element.hasOwnProperty('profileKey')) {
        temporaryChannelArray.push({ ...element, index, type: 'WhatsApp' })
      }
      this.formInput.channelType.push([])
    })
    this.channelItems = temporaryChannelArray
    //console.log(this.channelItems, 'this.channelItems')
    // Untuk fetch cust list & group
    this.loadCustomerListGroup()
  },
  methods: {
    async deleteChannel(idDeletion) {
      try {
        await this.$store.dispatch('campaign_manager/deleteChannel', idDeletion)
        const channelsData = await this.$store.dispatch('campaign_manager/readAllChannel')
        channelsData.map((e, index) => (e.no = index + 1))
        this.channelItems = channelsData
      } catch (error) {
        console.log(error)
      }
    },
    async createCampaign() {
      //console.log('createCampaign is invoked')

      const temporaryArrayContainingChannelDataAndContactsAssignedForCampaign = []
      for (const channelInput of this.formInput.channelType) {
        // const isChannelHasEmptyContacts = channelInput.length === 0 || !channelInput
        // if (isChannelHasEmptyContacts) {
        //   temporaryArrayContainingChannelDataAndContactsAssignedForCampaign.push({
        //     type: channelInput.label_server,
        //     name: channelInput.label,
        //     sosmed_id: channelInput.sosmed_id,
        //     profileKey: channelInput.profileKey,
        //   })
        // }
        if (channelInput.length === 0) continue
        const channelIndex = this.formInput.channelType.indexOf(channelInput)
        const channelData = this.channelItems[channelIndex]

        channelData.assignedContacts = []

        const channelInputIsAnArray = Array.isArray(channelInput)

        if (!channelInputIsAnArray) {
          channelData.assignedContacts.push(channelInput.assignedContacts)
        }

        if (channelInputIsAnArray) {
          for (const contactsArray of channelInput) {
            channelData.assignedContacts.push(contactsArray.contacts)
          }
        }
        if (!channelData.hasOwnProperty('profileKey')) {
          temporaryArrayContainingChannelDataAndContactsAssignedForCampaign.push(channelData)
        } else {
          //console.log(channelData, 'channelData 2')
          temporaryArrayContainingChannelDataAndContactsAssignedForCampaign.push({
            _id: channelData._id,
            type: channelData.label_server,
            label: channelData.label,
            sosmed_id: channelData.sosmed_id,
            status: channelData.status,
            profileKey: channelData.profileKey,
          })
        }
      }
      const formInputToBeSentToDatabase = {
        sub_id: this.user.sub_id,
        title: this.formInput.title,
        approvers: this.formInput.approvers,
        channelType: temporaryArrayContainingChannelDataAndContactsAssignedForCampaign,
        ID_for_editing: this.ID_for_editing,
      }

      //console.log(formInputToBeSentToDatabase)

      // if edit campaign
      if (this.value_isForUpdate) {
        //console.log('is editing campaign')
        await this.$store.dispatch('campaign_manager/editCampaign', formInputToBeSentToDatabase)
      }

      // if create campaign
      if (!this.value_isForUpdate) {
        //console.log('is creating campaign')
        const data = await this.$store.dispatch('campaign_manager/createCampaign', formInputToBeSentToDatabase)
        //console.log(data, 'data <<')
        this.$router.push({ path: `/campaign-manager/${data.id}/marketing-calendar` })
      }

      this.$store.commit('campaign_manager/SET_SELECTED_CONTACTS', '')
      await this.$store.dispatch('campaign_manager/fetchAllCampaigns')

      this.resetFormInput()

      this.isDialogVisible = false
    },
    closeDialog(value) {
      this.isDialogVisible = value
    },
    resetFormInput() {
      this.formInput = {
        title: '',
        approvers: [],
        contacts: [],
        channelType: [],
      }
    },
    // Untuk fetch cust list & group
    async loadCustomerListGroup() {
      const res = await this.$store.dispatch('global/getContactLists', {
        sub_id: this.user.sub_id,
        token: this.user.token,
      })
      if (res.status) {
        this.custList = res.data
      }

      this.allContact = []
      for (var list of this.custList) {
        this.allContact = this.allContact.concat(
          list.contacts.map(x => {
            return {
              ...x,
              list_name: list.list_name,
              list_id: list.list_id,
              label_color: list.label_color,
            }
          }),
        )
      }

      // remove duplicate by room id
      if (this.allContact.length < 5000) {
        this.allContact = this.allContact.filter(
          (room, index, that) =>
            index ===
            that.findIndex(t => t !== undefined && room !== undefined && t.phone_number === room.phone_number),
        )
      }

      this.$store.commit('global/SET_CUSTOMER_LIST_CONTACTS', this.allContact)
    },
    validate_allContactsOnThisChannelAreSelected(rowIndex) {
      // //console.log(rowIndex, 509)
      // //console.log(this.formInput.channelType[rowIndex]?.length)
      // //console.log(this.component_customerGroups.length)
      if (!this.formInput.channelType[rowIndex]) return false
      if (this.formInput.channelType[rowIndex]?.length === this.component_customerGroups.length) {
        return true
      }

      return false
    },
  },
}
</script>

<style scoped>
.text-field-font-style {
  font-family: 'Inter';
  font-weight: 400;
  font-size: 16px;
}

.campaign-title {
  margin-top: 32px;
  margin-left: 25px;
  margin-right: 25px;
}

.v-input__slot {
  align-items: center;
  justify-content: center;
}
</style>


