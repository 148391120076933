<template>
  <v-dialog
    v-model="show"
    max-width="620"
  >
    <v-card class="px-8 pb-8 pt-4">
      <v-card-title class="mb-8 pa-0">
        <v-row
          no-gutters
          align="center"
          justify="center"
        >
          <v-col cols="1">
            <v-btn
              icon
              plain
              @click.stop="show = false"
            >
              <v-icon>{{ icons.mdiClose }}</v-icon>
            </v-btn>
          </v-col>
          <v-col class="d-flex justify-center">
            <h4>Set Schedule</h4>
          </v-col>
          <v-col cols="1" />
        </v-row>
      </v-card-title>

      <!-- Select Channels -->
      <v-select
        v-model="selectedChannels"
        :items="allChannels"
        item-text="label"
        label="Select Channels"
        multiple
        chips
        persistent-hint
        return-object
      >
        <template v-slot:selection="{ item }">
          <v-chip>
            <v-badge
              dot
              inline
              left
              :color="item.color"
            ></v-badge>
            <span>{{ item.label }}</span>
          </v-chip>
        </template>
      </v-select>

      <!-- Start Time to post -->
      <v-combobox
        v-model="executionType"
        :items="execTypeOptions"
        :prepend-icon="icons.mdiSend"
        label="Time To Post"
        hide-details
        @change="changeExecType()"
      ></v-combobox>
      
      <v-row style="margin-top: 10px">
        <!-- Start Date Picker -->
        <v-col
          v-if="executionType.value !== 'now'"
          cols="12"
          md="6"
        >
          <v-menu
            ref="dateMenu"
            v-model="dateMenu"
            :close-on-content-click="false"
            :return-value.sync="pickedDate"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="pickedDate"
                label="Date"
                :prepend-icon="icons.mdiCalendarMonth"
                transition="scroll-y-transition"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="pickedDate"
              no-title
              scrollable
            >
              <v-spacer></v-spacer>
              <v-btn
                text
                color="primary"
                @click="dateMenu = false"
              >
                Cancel
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="$refs.dateMenu.save(pickedDate)"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
        <!-- End Date Picker -->

        <!-- Start Time Picker -->
        <v-col
          v-if="executionType.value !== 'now'"
          cols="12"
          md="6"
        >
          <v-menu
            ref="timeMenu"
            v-model="timeMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            :return-value.sync="pickedTime"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="pickedTime"
                label="Time"
                :prepend-icon="icons.mdiClockTimeEight"
                transition="scroll-y-transition"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-time-picker
              v-if="timeMenu"
              v-model="pickedTime"
              format="24hr"
              scrollable
              full-width
              :min="
                new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60000).toISOString().substr(11, 16)
              "
              @click:minute="$refs.timeMenu.save(pickedTime)"
            ></v-time-picker>
          </v-menu>
        </v-col>
        <!-- Start Time Picker -->
      </v-row>
      <!-- End Time to post -->

      <v-card-actions class="mt-4 pa-0">
        <v-spacer></v-spacer>

        <v-btn
          color="primary"
          outlined
          class="text-capitalize"
          @click="show = false"
        >
          Cancel
        </v-btn>

        <v-btn
          color="primary"
          class="text-capitalize text-white"
          @click="setSchedule"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
/* eslint-disable */
/* eslint-disable no-underscore-dangle */
import { mdiCalendarMonth, mdiClockTimeEight, mdiClose, mdiSend } from '@mdi/js'

const API_KEY = process.env.VUE_APP_AYRSHARE_KEY
export default {
  props: {
    value: { type: Boolean, required: true },
    postTemplate: { type: Object, default: () => {} },
    allChannels: { type: Array, default: () => [] },
  },
  setup() {
    return {
      icons: { mdiCalendarMonth, mdiClockTimeEight, mdiClose, mdiSend },
    }
  },
  data() {
    return {
      pickedDate: '',
      dateMenu: false,
      pickedTime: '',
      timeMenu: false,
      executionType: { text: 'Publish now', value: 'now' },
      execTypeOptions: [
        { text: 'Custom', value: 'custom' },
        { text: 'Publish now', value: 'now' },
        { text: 'Morning at 8AM', value: '08:00' },
        { text: 'Noon at 1PM', value: '13:00' },
        { text: 'Evening at 7PM', value: '19:00' },
      ],
      selectedChannels: [],
      listInstance: [],
      activeUserAyrshare: false,
    }
  },
  computed: {
    show: {
      get() {
        return this.value
      },
      set(value) {
        this.executionType = { text: 'Publish now', value: 'now' }
        this.$emit('input', value)
      },
    },
    schedulePostData() {
      return {
        time_to_post: this.executionType.text,
        date: this.pickedDate || this.getCurrentDateString(),
        time: this.pickedTime || this.getCurrentTimeString(),
        post_name: this.postTemplate.post_name,
        description: this.postTemplate.description,
        idCampaign: this.postTemplate.idCampaign,
        message_template: this.postTemplate.message_template,
        channels: this.selectedChannels,
      }
    },
    user() {
      return this.$store.getters['auth/getUser']
    },
  },

  async created() {
    this.selectedChannels = this.allChannels
    const { data } = await this.$store.dispatch('global/fetchProfileAyrshareBysubid', {
      token: this.user.token,
      sub_id: this.user.sub_id,
    })
    await fetch('https://app.ayrshare.com/api/profiles', {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${API_KEY}`,
      },
    })
      .then(res => res.json())
      .then(users => {
        this.lengthProfileAyrshare = users.profiles.lengthProfileAyrshare
        users.profiles.forEach(user => {
          if (user.refId === data[0]?.refId) {
            this.activeUserAyrshare = data[0]
          }
        })
      })
      .catch(console.error)
  },
  async mounted() {
    // get channels
    const instancePg = await this.$store.dispatch('global/getSettingsByKey', {
      sub_id: this.user.sub_id,
      token: this.user.token,
      key: 'instances',
    })
    if (instancePg.status) {
      this.listInstance = instancePg.data.value
        .filter(instance => !instance.fs_id && !instance.access_token)
        .filter(instance => Boolean(this.selectedChannels.find(c => instance._id === c.instance_id)))
    }
  },
  methods: {
    getCurrentDate() {
      return new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60 * 1000)
    },
    getCurrentDateString() {
      return this.getCurrentDate().toISOString().substring(0, 10)
    },
    getCurrentTimeString() {
      return this.getCurrentDate().toISOString().substring(11, 16)
    },
    changeExecType() {
      switch (this.executionType.value) {
        case 'now':
          this.pickedDate = ''
          this.pickedTime = ''
          break
        case 'custom':
          this.pickedDate = this.getCurrentDateString()
          this.pickedTime = this.getCurrentTimeString()
          break
        default:
          this.pickedDate = this.getCurrentDateString()
          this.pickedTime = this.executionType.value
          break
      }
    },

    // getChannels() {
    //   return this.$store.getters['campaign_manager/getAllCampaignsData'].filter(
    //     campaign => campaign.id === this.schedulePostData.idCampaign,
    //   )[0].channelType
    // },
    async setSchedule() {
      const broadcastPayload = {
        name: this.schedulePostData.post_name,
        is_paused: false,
        broadcast_config: {
          mode: this.postTemplate.broadcastMode,
          speed: 10,
          deleteForMe: false,
          type: 'normal',
          resend_time: 'default',
          timezone: new Date(`${this.schedulePostData.date} ${this.schedulePostData.time}`)
            .toString()
            // eslint-disable-next-line no-useless-escape
            .match(/([A-Z]+[\+-][0-9]+.*)/)[1],
        },
        sub_id: this.user.sub_id,
        message_template: {
          ...this.schedulePostData.message_template,
          message: this.schedulePostData.description,
          ad_hoc: true,
          on_reply_message: {
            attachments: [],
            message: '',
          },
        },
        schedule_send: new Date(`${this.schedulePostData.date}T${this.schedulePostData.time}`).getTime(),
        channels: this.selectedChannels,
      }
      const schedule = await this.$store.dispatch('marketingCalendar/createSchedule', {
        sub_id: this.user.sub_id,
        data: { ...this.schedulePostData, broadcastPayload },
        token: this.user.token,
      })
      const post = await this.$store.dispatch('marketingCalendar/postScheduleToSosmed', {
        schedule: this.schedulePostData.description,
        images: this.schedulePostData.message_template.attachments?.map(el => el.url),
        channels: this.selectedChannels.filter(el => el.type).map(elm => elm.type),
        profileKey: this.activeUserAyrshare.profileKey,
        data: {
          token: this.user.token,
          sub_id: this.user.sub_id,
          schedulePostData: { ...schedule },
        },
      })
      //console.log(post, 'post')
      this.show = false
    },
  },
}
</script>

<style lang="scss" scoped></style>
