<template>
  <v-dialog
    v-model="isDialogVisible"
    max-width="620"
  >
    <v-card>
      <!-- Header -->
      <v-card-title>
        <v-row
          no-gutters
          align="center"
          justify="center"
        >
          <v-col cols="1">
            <v-btn
              icon
              plain
              @click.stop="isDialogVisible = false"
            >
              <v-icon>{{ icons.mdiClose }}</v-icon>
            </v-btn>
          </v-col>
          <v-col class="d-flex justify-center">
            <h4>Rejection note</h4>
          </v-col>
          <v-col cols="1" />
        </v-row>
      </v-card-title>

      <!-- Content -->
      <v-card-text>
        <div
          v-if="typeof formattedNotesRejection === 'object'"
          class="text-justify"
        >
          <p
            v-for="(note, i) in formattedNotesRejection"
            :key="i"
            class="text-justify"
          >
            {{ note }}
          </p>
        </div>
        <p
          v-else
          class="text-justify"
        >
          {{ formattedNotesRejection }}
        </p>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mdiClose, mdiCommentTextOutline } from '@mdi/js'

export default {
  setup() {
    return {
      icons: { mdiClose, mdiCommentTextOutline },
    }
  },
  props: {
    value: { type: Boolean, required: true },
    postTemplate: { type: Object, default: () => {} },
  },
  emits: [],
  data() {
    return {
      rejectionNoteContent: `Minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.

Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua ipsum dolor sit.`,
    }
  },
  computed: {
    isDialogVisible: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
    formattedNotesRejection() {
      if (this.postTemplate?.approvement?.length !== undefined) {
        return this.postTemplate?.approvement?.map(approver => {
          return approver.notesRejected
        })
      }

      return this.postTemplate?.approvement?.notesRejected
    },
  },
  methods: {
    editPost() {
      // TODO edit post functionality

      //console.log('TODO edit post functionality')
    },
  },
}
</script>

<style lang="scss" scoped></style>