<template>
  <v-col
    cols="12"
    lg="auto"
  >
    <v-card
      outlined
      :class="$vuetify.breakpoint.lgAndUp ? 'sidebar-card' : ''"
    >
      <!-- Header -->
      <div class="sidebar-header">
        <v-select
          v-model="selectedFilter"
          :items="filterBy"
          label="Post Template"
          dense
          outlined
          :prepend-icon="icons.mdiFilter"
        ></v-select>
      </div>
      <!-- Content -->
      <div class="content">
        <div class="content-row">
          <!-- Create Post Button -->
          <div
            class="btn-container"
          >
            <v-btn
              depressed
              color="primary"
              class="text-capitalize create-post-btn"
              @click="$router.push({ name: 'create-post', params: { campaignId: $route.params.id } })"
            >
              <v-icon color="white">
                {{ icons.mdiPlus }}
              </v-icon>
            </v-btn>
          </div>

          <div
            v-for="post in filteredPostTemplates"
            :key="post.id"
            class="img-container"
          >
            <v-hover v-slot="{ hover }">
              <div style="position: relative">
                <v-img
                  :lazy-src="post.message_template.attachments[0].url"
                  height="120px"
                  width="120px"
                  :src="post.message_template.attachments[0].url"
                >
                </v-img>

                <v-chip
                  :color="post.isDraft ? '#808080' : statusColor(post.statusApproval)"
                  :text-color="post.isDraft ? 'white' : statusColor(post.statusApproval)"
                  label
                  x-small
                  style="position: absolute; top: 0; right: 0; margin: 4px;"
                >
                  {{ post.isDraft ? 'Draft' : post.statusApproval }}
                </v-chip>

                <!-- Hover state -->
                <transition name="fade">
                  <div
                    v-if="hover"
                    class="img-overlay"
                  >
                    <v-btn
                      class="text-capitalize edit-btn"
                      @click.stop="toEditPost(post)"
                    >
                      <v-icon left>
                        {{ icons.mdiPencil }}
                      </v-icon>
                      <span>Edit</span>
                    </v-btn>
                    <v-btn
                      v-if="post.statusApproval === 'Approved'"
                      class="text-capitalize schedule-btn"
                      @click.stop="toSetSchedule(post)"
                    >
                      <v-icon left>
                        {{ icons.mdiClock }}
                      </v-icon>
                      <span>Schedule</span>
                    </v-btn>
                    <v-btn
                      v-if="post.statusApproval === 'Rejected'"
                      class="text-capitalize schedule-btn"
                      @click.stop="toRejectionNote(post)"
                    >
                      <v-icon
                        color="black"
                        dense
                      >
                        {{ icons.mdiCommentTextOutline }}
                      </v-icon>
                    </v-btn>
                  </div>
                </transition>
              </div>
            </v-hover>
          </div>
        </div>
      </div>
    </v-card>
    
    <set-schedule-dialog
      v-model="showSetScheduleModal"
      :post-template="postTemplate"
      :all-channels="allChannels"
    />
    <rejection-note-dialog 
      v-model="showRejectionNoteModal"
      :post-template="postTemplate"
    />
  </v-col>
</template>

<script>
/* eslint-disable no-underscore-dangle */
import { mdiFilter, mdiPencil, mdiClock, mdiPlus, mdiCommentTextOutline } from '@mdi/js'
import SetScheduleDialog from './dialog/SetScheduleDialog.vue'
import RejectionNoteDialog from './dialog/RejectionNoteDialog.vue'

export default {
  name: 'PostContentList',
  components: { SetScheduleDialog, RejectionNoteDialog },
  setup() {
    return {
      icons: {
        mdiFilter,
        mdiPencil,
        mdiClock,
        mdiPlus,
        mdiCommentTextOutline,
      },
    }
  },
  data() {
    return {
      showSetScheduleModal: false,
      showRejectionNoteModal: false,
      filterBy: ['All', 'Approved', 'On Process', 'Draft'],
      selectedFilter: 'All',
      postTemplates: [],
      postTemplate: {},
      allChannels: [],
    }
  },
  computed: {
    user() {
      return this.$store.getters['auth/getUser']
    },
    filteredPostTemplates() {
      switch (this.selectedFilter) {
        case 'Approved':
          return this.postTemplates.filter(post => post.statusApproval === 'Approved')
        case 'On Process':
          return this.postTemplates.filter(post => !post.isDraft && post.statusApproval === 'Need Approval')
        case 'Draft':
          return this.postTemplates.filter(post => post.isDraft)
        case 'All':
        default:
          return [
            ...this.postTemplates.filter(p => p.statusApproval === 'Approved'),
            ...this.postTemplates.filter(p => p.statusApproval === 'Rejected'),
            ...this.postTemplates.filter(p => p.statusApproval === 'Need Approval' && !p.isDraft),
            ...this.postTemplates.filter(p => p.isDraft),
          ]
      }
    },
  },
  async created() {
    await this.getPostTemplatesData()
    await this.getChannelsData()
  },
  methods: {
    async getPostTemplatesData() {
      await this.$store.dispatch('marketingCalendar/fetchPostTemplates', {
        sub_id: this.user.sub_id,
        data: {
          idCampaign: this.$route.params.id,
        },
        token: this.user.token,
      })
      this.postTemplates = this.$store.getters['marketingCalendar/getPostTemplates']
    },
    async getChannelsData() {
      const response = await this.$store.dispatch('global/getSettingsByKey', {
        sub_id: this.user.sub_id,
        token: this.user.token,
        key: 'instances',
      })

      this.allChannels = response.data.value.map(element => {
        return {
          label: element.label,
          label_server: element.label_server,
          color: element.color,
          instance_id: element.instance_id,
          is_loading: element.is_loading,
          phone_number: element.phone_number,
          setting_sync: element.setting_sync,
          status: element.status,
          sub_id: element.sub_id,
          _id: element._id,
        }
      })
    },
    async toSetSchedule(postTemplate) {
      const { data } = await this.$store.dispatch('campaign_manager/fetchSpecifiedCampaign', {
        ID_for_fetching: postTemplate.idCampaign,
      })
      //console.log(data, 'datadata')
      this.allChannels = data.channelType
      //console.log(this.allChannels, 'this.allChannels')
      this.postTemplate = postTemplate
      this.showSetScheduleModal = true
    },
    toRejectionNote(postTemplate) {
      this.postTemplate = postTemplate
      this.showRejectionNoteModal = true
    },
    statusColor(statusApproval) {
      switch (statusApproval) {
        case 'Approved':
          return 'success'
        case 'Need Approval':
          return 'warning'
        case 'Rejected':
          return 'error'
        default:
          return ''
      }
    },
    async toEditPost(post) {
      await this.$store.dispatch('marketingCalendar/fetchPostTemplate', {
        sub_id: this.user.sub_id,
        data: {
          postId: post.id,
        },
        token: this.user.token,
      })

      this.$router.push({
        name: 'edit-post',
        params: { campaignId: post.idCampaign, postId: post.id, page: 'Edit Post' },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.row {
  flex-wrap: nowrap !important;
}

.sidebar-card {
  margin: 0 auto;
  width: 320px;
}

.sidebar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 12px 0 12px;

  div.v-input {
    flex-basis: 50%;
  }
}

.content {
  overflow-x: scroll;
  height: 20vh;
  max-height: 20vh;

  @media (min-width: 1264px) {
    overflow-x: hidden;
    overflow-y: scroll;
    height: 72vh;
    max-height: 72vh;
  }
}

.content-row {
  display: flex;
  flex-direction: row;
  align-items: center;

  @media (min-width: 1264px) {
    flex-wrap: wrap;
  }
}

.img-container,
.btn-container {
  display: inline-flex;
  padding: 0 15px;
  margin: 8px 0;
  height: 120px;
}

.img-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(black, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.v-btn.edit-btn,
.v-btn.schedule-btn {
  padding: 0;
  margin: 4px 0;
  font-size: 11px;
  min-width: 75%;
  width: 75%;
  height: 24px !important;
}

.v-btn.create-post-btn {
  min-width: 120px;
  width: 120px;
  height: 120px !important;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>